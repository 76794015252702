import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ContaCorrenteService } from '../contacorrente.service';
import { TipoNotificacao } from 'src/app/core/notificacao/notificacao.model';
import { NotificacaoService } from 'src/app/core/notificacao/notificacao.service';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../../../shared/shared.module';
import { AutenticacaoService } from '../../autenticacao/autenticacao.service';
import { LoginParamsInterno, LoginResult } from '../../autenticacao/autenticacao.model';

@Component({
  selector: 'app-convite-usuario',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './convite-usuario.component.html',
  styleUrl: './convite-usuario.component.scss'
})
export class ConviteUsuarioComponent {

  private _contaCorrenteService = inject(ContaCorrenteService);
  private _notificacaoService = inject(NotificacaoService);
  private _autenticacaoService = inject(AutenticacaoService);
  private _router = inject(Router);
  dadosConviteContaCorrente: any;
  contaCorrente: any;
  usuarioLogado: any;
  conviteId: string;
  loginParamsInterno: LoginParamsInterno;
  usuario: any;
  contaCorrentes: any[];
  public _loadDataSpinner: boolean = true;
  public _showError: boolean = false;
  public descricaoErro: string = "";

  ngOnInit() {
   
    this.conviteId = this._router.routerState.snapshot.root.queryParamMap.get("conviteId") ?? "";

    this.usuarioLogado = this._autenticacaoService.obterDadosUsuarioLogado() as LoginResult;
    if (this.usuarioLogado?.usuarioInfo.perfil == "Cliente") {
      if (localStorage.getItem('currentContaCorrente')) {
        this.contaCorrente = JSON.parse(localStorage.getItem('currentContaCorrente') ?? "");
      }
    }

    this._loadDataSpinner = true;
    this._contaCorrenteService.aceitarConviteUsuarioContaCorrente(this.conviteId).subscribe({
      next: (result) => {
        this.dadosConviteContaCorrente = result.data;
        this._loadDataSpinner = false;
        this._showError = false;
        this.reprocessaLoginInterno();
      },
      error: (error) => {
        this._notificacaoService.show(TipoNotificacao.erro, error.error.message, "Atenção");
        localStorage.clear();
        this._loadDataSpinner = false;  
        this._showError = true;
        this.descricaoErro = error.error.message; 
      }
    }).add(() => {
      this._loadDataSpinner = false;
    })
  }

  reprocessaLoginInterno() {

    localStorage.removeItem('currentContaCorrente');
    this.loginParamsInterno = {
      nome: this.usuarioLogado.usuarioInfo.nomeCompleto,
      email: this.usuarioLogado.usuarioInfo.email,
      perseusAuthId: this.usuarioLogado.usuarioInfo.id
    };

    this._autenticacaoService.login(this.loginParamsInterno).subscribe
      ({
        next: (resLoginInterno :any) =>
        {
          this.usuario = resLoginInterno.data;

          this.contaCorrentes = resLoginInterno.data?.contasCorrente;
          let contaCorrentePadrao = this.contaCorrentes.filter(x => x.contaPadrao == true)[0];
          if (contaCorrentePadrao != null) {
            localStorage.setItem('currentContaCorrente', JSON.stringify(contaCorrentePadrao));
          }
          else // DEFINI A PRIMEIRA CONTA COMO PADRÃO
          {
            this.definirContaPadrao(this.contaCorrentes[0]);
            localStorage.setItem('currentContaCorrente', JSON.stringify(this.contaCorrentes[0]));
          }
        },
        error: (error) => {
          this._notificacaoService.show(TipoNotificacao.erro, error.error.message, "Atenção");
        }
      });
      // FIM PROCESSO LOGIN INTERNO
}

async definirContaPadrao(contaCorrente: any) {
  this._autenticacaoService.definirContaPadrao(contaCorrente)
    .subscribe
    ({
      next: (resContaPadrao) => {
      }, error: (error) => {
        this._notificacaoService.show(TipoNotificacao.erro, error.error.message, "Atenção");
      }
    });
}

retornarHome() {
  if (this.usuarioLogado?.usuarioInfo.perfil == 'Cliente') {
    this._router.navigate(['/conta-corrente/extrato']);
  } else if (this.usuarioLogado?.usuarioInfo.perfil == 'Administrador') {
    this._router.navigate(['/clientes']);
  }
}

retornarLogin(){
  localStorage.clear();
  window.location.href = environment.aplicacao.url;
}

}
