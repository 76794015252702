import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  get<T>(key: string): T | null {
    if (isPlatformBrowser(this.platformId)) {
      let lStorageValue = localStorage.getItem(key) || null;

      if (lStorageValue != null) {
        return <T>JSON.parse(atob(lStorageValue));
      }
    }

    return null;
  }

  set<T>(key: string, value: T) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, btoa(JSON.stringify(value)))
    }
  }

  remove(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
    }
  }
}
