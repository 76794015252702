import { Injectable, inject } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { TipoNotificacao } from './notificacao.model';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  private _toast = inject(ToastrService);
  private opcoesPadrao: Partial<IndividualConfig<any>> = {
    closeButton: true,
    progressBar: true,
    timeOut: 3000,
    disableTimeOut: false
  };

  show(tipo: TipoNotificacao, mensagem: string, titulo?: string, opcoes?: Partial<IndividualConfig<any>>) {
    switch (tipo) {
      case TipoNotificacao.alerta: this._toast.warning(mensagem, titulo, opcoes ?? this.opcoesPadrao); break;
      case TipoNotificacao.info: this._toast.info(mensagem, titulo, opcoes ?? this.opcoesPadrao); break;
      case TipoNotificacao.sucesso: this._toast.success(mensagem, titulo, opcoes ?? this.opcoesPadrao); break;
      case TipoNotificacao.erro: this._toast.error(mensagem, titulo, opcoes ?? this.opcoesPadrao); break;
    }
  }
}
