import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoadingService } from './loading.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  showLoaderCustom: any[] = [];
  constructor(private loaderService: LoadingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.loaderService.show();
    this.showLoaderCustom.push(request);
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.showLoaderCustom.pop();
        if (this.showLoaderCustom.length == 0) {
          this.loaderService.hide();
        }
      }
    },
      (err: any) => {
        this.showLoaderCustom.pop();
        if (this.showLoaderCustom.length == 0) {
          this.loaderService.hide();
        }
      }));
  }


}
