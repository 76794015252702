
import { Component } from '@angular/core';
import { MaterialModule } from '../../../material.module';
import { CardOperacaoInfo } from './card-operacoes.model';
import { TablerIconsModule } from 'angular-tabler-icons';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-card-operacoes',
    standalone: true,
    imports: [MaterialModule, TablerIconsModule, CommonModule],
    templateUrl: './card-operacoes.component.html',
})
export class CardOperacoesComponent {
    stats: CardOperacaoInfo[] = [
        {
            id: 3,
            cor: 'success',
            titulo: 'Pagamentos',
            subtitulo: 'Recebidos',
            img: 'premium-rights',
            valor: 235,
        },
        {
            id: 3,
            cor: 'success',
            titulo: 'Transferências',
            subtitulo: 'Recebidas',
            img: 'transfer',
            valor: 235,
        },
        {
            id: 5,
            cor: 'error',
            titulo: 'Pagamentos',
            subtitulo: 'Realizados',
            img: 'premium-rights',
            valor: 235,
        },
        {
            id: 4,
            cor: 'error',
            titulo: 'Transferências',
            subtitulo: 'Enviadas',
            img: 'transfer',
            valor: 6235,
        },
        {
            id: 5,
            cor: 'error',
            titulo: 'Saques',
            subtitulo: 'Realizados',
            img: 'receipt-2',
            valor: 123.5,
        },

    ];
}
