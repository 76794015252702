   <!-- loading layout replaced by app after startupp -->
   <div class="app-loading" *ngIf="_loadingSplashScreen">
    <div class="logo">
      <img src="./assets/images/logos/dark-logo.png" width="80%" alt="CA">
    </div>
    <svg class="spinner" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
  </div>

<div class="container" *ngIf="!checkContaCorrenteExistente && !_loadingSplashScreen">
  <mat-card class="cardWithShadow" style="padding: 20px">
    <div class="row">
      <div class="col-md-12 text-center">
        <img
          src="./assets/images/logos/dark-logo.png"
          alt="error-bg"
          style="width: 200px; height: 100"
        />
      </div>
      <div class="col-md-12 text-center" style="margin-bottom: 20px">
        <h2>Atenção!</h2>
      </div>
      <div class="col-md-12 bg-light-warning" style="margin-bottom: 20px">
        <p>
          Prezado usuário <strong> {{ this.usuario.nome }}, </strong>
        </p>
        <p>
          <strong> Não foi possível realizar seu login no PerseusPay </strong>
          você ainda não possui nenhuma conta corrente ativa. Contate o
          Administrador do Sistema.
        </p>
      </div>
      <hr />
      <div class="col-md-12 text-center">
        <a mat-flat-button color="primary" (click)="retornarLogin()"
          >Retornar para o Login</a
        >
      </div>
    </div>
  </mat-card>
</div>
