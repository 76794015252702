<mat-card class="shadow-none bg-light-{{ cardInfo?.cor }}">
    <mat-card-content class="p-20">
        <i-tabler [name]="cardInfo!.icon" class="d-flex text-{{ cardInfo?.cor }}"
            style="height: 40px; width: 40px;"></i-tabler>
        <h4 class="mat-subtitle-2 f-w-600 text-{{ cardInfo?.cor }} m-t-8">
            {{ cardInfo?.titulo }}
        </h4>
        <h6 class="m-t-4 mat-subtitle-1 f-w-600 text-{{ cardInfo?.cor }} m-t-8">
            {{ cardInfo?.valor | currency }}
        </h6>
         </mat-card-content>
</mat-card>
