export const environment = {
  name: 'Development',

  api: {
    uri: 'https://api-dev.pay.perseus.com.br',
  },
  auth: {
    uri: 'https://auth-dev.perseus.com.br',
  },
  auth_api: {
    uri: 'https://api-dev.auth.perseus.com.br',
  },
  aplicacao: {
    nome: 'Perseus Pay Dev',
    id: 'Perseus.Pay',
    url:'https://pay-dev.perseus.com.br'
  },
  keys: {
    userInfo: 'X-User-Info-Dev',
    contaInfo: 'X-Conta-Info-Dev',
  },
};
