import { Component } from '@angular/core';
import { CoreService } from 'src/app/shared/services/core.service';

@Component({
  selector: 'app-branding',
  standalone: true,
  template: `
    <div class="branding">
      @if(options.theme === 'light') {
      <a href="/" class="logo-desktop">
        <img
          src="./assets/images/logos/dark-logo.png"
          class="align-middle m-2"
          alt="logo"
        />
      </a>
      <a href="/" class="logo-mobile">
        <img
          src="./assets/images/logos/dark-logo-mobile.png"
          class="align-middle m-2"
          alt="logo"
        />
      </a>
      } @if(options.theme === 'dark') {
      <a href="/" class="logo-desktop">
        <img
          src="./assets/images/logos/light-logo.png"
          class="align-middle m-2"
          alt="logo"
        />
      </a>
      <a href="/" class="logo-mobile">
        <img
          src="./assets/images/logos/light-logo-mobile.png"
          class="align-middle m-2"
          alt="logo"
        />
      </a>
      }
    </div>
  `,
})
export class BrandingComponent {
  options = this.settings.getOptions();

  constructor(private settings: CoreService) {}
}
