<mat-card class="cardWithShadow">
    <mat-card-content class="p-y-20">
        <mat-card-title>Entradas e saídas</mat-card-title>
        <mat-card-subtitle class="mat-body-1 f-s-12 m-b-32 ">Referente ao período 04/02/2024 até
            04/03/2024.</mat-card-subtitle>

        @for(stat of stats; track stat.cor) {
        <div class="m-t-16 m-b-8">
            <div class="d-flex align-items-center">
                <span class="text-{{ stat.cor }} bg-light-{{
              stat.cor
            }} rounded icon-40 d-flex align-items-center justify-content-center">
                    <i-tabler [name]="stat.img" class="icon-20 d-flex"></i-tabler>
                </span>

                <div class="m-l-16 m-r-auto">
                    <h6 class="mat-subtitle-1 f-s-16 f-w-600 l-h-15">{{ stat.titulo }}</h6>
                    <span class="mat-body-1">{{ stat.subtitulo }}</span>
                </div>
                <span class="f-w-600 f-s-14 text-{{ stat.cor }}"> {{ stat.valor | currency : 'BRL'}} </span>
            </div>
        </div>
        }
    </mat-card-content>
</mat-card>