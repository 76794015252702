import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cliente-convite-redirect',
  templateUrl: './cliente-convite-redirect.component.html',
  styleUrl: './cliente-convite-redirect.component.scss'
})
export class ClienteConviteRedirectComponent {


  private _router = inject(Router);
  conviteId: string;
  ngOnInit(): void {
    
    localStorage.clear();
    this._router.navigate(["/convite-usuario"],{ queryParamsHandling:"preserve" });
  }

}
