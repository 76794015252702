<span [dir]="options.dir!">
  <mat-sidenav-container class="mainWrapper" autosize autoFocus [ngClass]="{
    'sidebarNav-mini': options.sidenavCollapsed && options.navPos !== 'top' &&  !resView,
    cardBorder: options.cardBorder,
    orange_theme: options.activeTheme == 'orange_theme',
    blue_theme: options.activeTheme == 'blue_theme',
    aqua_theme: options.activeTheme == 'aqua_theme',
    purple_theme: options.activeTheme == 'purple_theme',
    green_theme: options.activeTheme == 'green_theme',
    cyan_theme: options.activeTheme == 'cyan_theme'
  }">

    <mat-sidenav #leftsidenav [mode]="isOver ? 'over' : 'side'"
      [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver && !resView"
      (openedChange)="onSidenavOpenedChange($event)" (closedStart)="onSidenavClosedStart()" class="sidebarNav">
      <div class="flex-layout">
        <app-sidebar (toggleMobileNav)="sidenav.toggle()" [showToggle]="isOver"></app-sidebar>
        <ng-scrollbar class="position-relative" style="height: 100%">
          <mat-nav-list class="sidebar-list">
            @for(item of navItems; track item) {
            <app-nav-item [item]="item" (notify)="sidenav.toggle()">
            </app-nav-item>
            }
          </mat-nav-list>
        </ng-scrollbar>
      </div>
    </mat-sidenav>


    <mat-sidenav-content class="contentWrapper" #content>


      <app-header [showToggle]="!isOver" (toggleCollapsed)="toggleCollapsed()" (toggleMobileNav)="sidenav.toggle()"
        (toggleMobileFilterNav)="filterNavRight.toggle()" (optionsChange)="receiveOptions($event)"></app-header>


      <main class="pageWrapper" [ngClass]="{
        maxWidth: options.boxed
      }">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <!-- ------------------------------------------------------------------
    Mobile Apps Sidebar
    ------------------------------------------------------------------ -->
  <mat-drawer #filterNavRight mode="over" position="end" class="filter-sidebar">
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="branding">
          @if(options.theme === 'light') {
          <a href="/">
            <img src="./assets/images/logos/dark-logo.svg" class="align-middle m-2" alt="logo" />
          </a>
          } @else {
          <a href="/">
            <img src="./assets/images/logos/light-logo.svg" class="align-middle m-2" alt="logo" />
          </a>
          }
        </div>
        <button mat-icon-button (click)="filterNavRight.toggle()" class="d-flex justify-content-center">
          <i-tabler name="x" class="icon-18 d-flex"></i-tabler>
        </button>
      </div>

      <mat-accordion>
        <mat-expansion-panel class="shadow-none">
          <mat-expansion-panel-header>
            <mat-panel-title class="f-s-16 f-w-500"> Apps </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <div class="row">
              @for(appdd of apps; track appdd.img) {
              <div class="col-12 text-hover-primary">
                <a [routerLink]="[appdd.link]" class="d-flex align-items-center text-decoration-none m-b-24 gap-16">
                  <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
                    <img [src]="appdd.img" width="20" />
                  </button>

                  <div>
                    <h5 class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text">
                      {{ appdd.title }}
                    </h5>
                    <span class="mat-body-1 f-s-12">{{ appdd.subtitle }}</span>
                  </div>
                </a>
              </div>
              }
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-drawer>
</span>
