import { NavItem } from './nav-item';

export const navItemsCliente: NavItem[] = [
  // {
  //   navCap: 'Início',
  // },
  // {
  //   displayName: 'Dashboard',
  //   iconName: 'layout-dashboard',
  //   route: '/dashboard',
  // },

  {
    navCap: 'Minha conta',
    navCapMobile: 'Conta',
  },
  {
    displayName: 'Painel',
    iconName: 'chart-bar',
    route: '/painel',
  },
  {
    displayName: 'Extrato',
    iconName: 'file-text',
    route: '/conta-corrente/extrato',
  },
  {
    displayName: 'Saques',
    iconName: 'receipt-2',
    route: 'conta-corrente/saque',
  },
  {
    navCap: 'Cobrança',
    navCapMobile: 'Cobr.',
  },
  {
    displayName: 'Transações',
    iconName: 'credit-card-pay',
    route: 'cobranca/transacoes',
  },
  /*{
    displayName: 'Notas fiscais',
    iconName: 'file-invoice',
    route: 'cobranca/notas-fiscais',
  }, */
  {
    navCap: 'Configurações',
    navCapMobile: 'Config.',
  },
  {
    displayName: 'Dados da conta',
    iconName: 'list-details',
    route: 'conta-corrente/dados-cadastrais',
  },
  {
    displayName: 'Permissões',
    iconName: 'shield-lock',
    children: [
      {
        displayName: 'Usuários',
        iconName: 'point',
        route: 'configuracoes/usuarios',
      },
      /* {
        displayName: 'Credenciais API',
        iconName: 'point',
        route: 'configuracoes/seguranca/credenciais-api',
      } */
    ],
  },
  {
    displayName: 'Contas bancárias',
    iconName: 'building-bank',
    route: 'configuracoes/contas-bancarias',
  },
];
