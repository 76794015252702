import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from 'src/app/core/http-request/http-request.service';

@Injectable({
  providedIn: 'root',
})
export class ContaCorrenteService {
  private _httpRequest = inject(HttpRequestService);

  /**
   * Requisição HTTP para obter dados da conta corrente
   */
  obterDadosContaCorrente(contaCorrenteId: string): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/obter-dados-conta-corrente/?psContaCorrenteId=${contaCorrenteId}`
    );
  }

  /**
   * Requisição HTTP para obter extrato conta corrente
   */
  obterExtratoContaCorrente(params: any): Observable<any> {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/extrato-conta-corrente`,
      params
    );
  }

  /**
   * Requisição HTTP para exportar extrato conta corrente
   */
  exportarExtratoContaCorrente(params: any): Observable<any> {
    return this._httpRequest.postBlob<any>(
      `/v1/contacorrente/exportar-extrato-conta-corrente`,
      params
    );
  }

  /**
   * Requisição HTTP para listar as contas correntes do usuário
   */
  listarContasCorrente(): Observable<any> {
    return this._httpRequest.get<any>(`/v1/usuario/listar-contas-corrente`);
  }

  /**
   * Requisição HTTP para listar as contas correntes master
   */
  listarContasCorrenteMaster(): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/listar-contas-corrente-master`
    );
  }

  /**
   * Requisição HTTP para listar-conta corrente-meios de pagamento
   */
  listarContaCorrenteMeiosPagamento(
    psContaCorrenteId: string
  ): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/listar-conta-corrente-meio-pagamento/?psContaCorrenteId=${psContaCorrenteId}`
    );
  }

   /**
   * Requisição HTTP para listar-conta corrente-meios de pagamento ativa
   */
   listarContaCorrenteMeiosPagamentoAtivas(
    psContaCorrenteId: string
  ): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/listar-conta-corrente-meio-pagamento-ativa/?psContaCorrenteId=${psContaCorrenteId}`
    );
  }

  /**
   * Requisição HTTP para definir conta corrente padrão usuário
   */
  definirContaPadrao(params: any): Observable<any> {
    return this._httpRequest.post<any>(
      `/v1/usuario/definir-conta-padrao`,
      params
    );
  }

  /******************************************************** SAQUE ********************************************************/
  /**
   * Requisição HTTP para solicitação de saque
   */
  solicitarSaque(params: any): Observable<any> {
    return this._httpRequest.post<any>(`/v1/saque/solicitar-saque`, params);
  }

  /**
   * Requisição HTTP para obter Solicitação de Saque
   */
  obterSolicitacaoSaque(psContaCorrenteSaqueId: string): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/saque/obter-solicitacao-saque/?psContaCorrenteSaqueId=${psContaCorrenteSaqueId}`
    );
  }

  /**
   * Requisição HTTP para cancelar Solicitação de Saque
   */
  cancelarSolicitacaoSaque(psContaCorrenteSaqueId: string): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/saque/cancelar-solicitacao-saque/?psContaCorrenteSaqueId=${psContaCorrenteSaqueId}`
    );
  }

  /**
   * Requisição HTTP para obter Tarifa Saque Conta Corrente
   */
  consultarTarifaSaque(psContaCorrenteId: string): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/saque/consultar-tarifa-saque/?psContaCorrenteId=${psContaCorrenteId}`
    );
  }

  /**
   * Requisição HTTP para obter a lista de saques por conta corrente
   */
  listarSaquesContaCorrente(psContaCorrenteId: string): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/saque/listar-saques-conta-corrente/?psContaCorrenteId=${psContaCorrenteId}`
    );
  }

  /**
   * Requisição HTTP para obter a lista de saques
   */
  listarSaques(params: any): Observable<any> {
    return this._httpRequest.post<any>(`/v1/saque/listar-saques`, params);
  }

  /**
   * Requisição HTTP para efetivar solicitação de saque
   */
  efetivarSaque(params: any): Observable<any> {
    return this._httpRequest.post<any>(`/v1/saque/processar-saque`, params);
  }

  /**
   * Requisição HTTP para baixar comprovante de saque
   */
  baixarComprovanteSaque(psContaCorrenteSaqueId: string): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/saque/baixar-comprovante-saque/?psContaCorrenteSaqueId=${psContaCorrenteSaqueId}`
    );
  }

  /**
   * Requisição HTTP para enviar convite para usuário conta corrente
   */
  convidarUsuarioContaCorrente(
    psNomeConvidado: string,
    psEmailConvidado: string,
    psContaCorrenteId: string,
    psPerfilAcessoConta: string
  ) {
    return this._httpRequest.get<any>(
      `/v1/convitecontacorrente/convidar-usuario-conta-corrente/?psNomeConvidado=${psNomeConvidado}&psEmailConvidado=${psEmailConvidado}&psContaCorrenteId=${psContaCorrenteId}&psPerfilAcessoConta=${psPerfilAcessoConta}`
    );
  }

  /**
   * Requisição HTTP para aceitar convite usuário conta corrente
   */
  aceitarConviteUsuarioContaCorrente(psConviteUsuarioContaCorrenteId: string) {
    return this._httpRequest.get<any>(
      `/v1/convitecontacorrente/aceitar-convite-usuario-conta-corrente/?psConviteUsuarioContaCorrenteId=${psConviteUsuarioContaCorrenteId}`
    );
  }

  /**
   * Requisição HTTP para listar as contas correntes
   */
  listarTodasContasCorrente(
    psClienteId: string = '',
    liskip: number = 0,
    litake: number = 0
  ): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/listar-contas-corrente/?psClienteId=${psClienteId}&liskip=${liskip}&litake=${litake}`
    );
  }

  /**
   * Requisição HTTP para listar as contas correntes com filtros
   */
  ListarContaCorrenteComFiltros(params: any): Observable<any> {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/listar-contas-corrente-com-filtros`,
      params
    );
  }

  /**
   * Requisição HTTP para ativar conta corrente
   */
  ativarContaCorrente(psContaCorrenteId: string) {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/ativar-conta-corrente/?psContaCorrenteId=${psContaCorrenteId}`
    );
  }

  /**
   * Requisição HTTP para inativar conta corrente
   */
  inativarContaCorrente(psContaCorrenteId: string) {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/inativar-conta-corrente/?psContaCorrenteId=${psContaCorrenteId}`
    );
  }

  /**
   * Requisição HTTP para listar conta corrente meio de pagamento
   */
  listarContaCorrenteMeioPagamento(psContaCorrenteId: string) {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/listar-conta-corrente-meio-pagamento/?psContaCorrenteId=${psContaCorrenteId}`
    );
  }

  /**
   * Requisição HTTP para criar meio de pagamento
   */
  criarMeioPagamento(params: any) {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/criar-meio-pagamento`,
      params
    );
  }

  /**
   * Requisição HTTP para encerrar precificação
   */
  encerrarPrecificacao(params: any) {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/encerrar-precificacao`,
      params
    );
  }

  /* Requisição HTTP para listar movimento conta corrente */
  listarMovimentoContaCorrenteManual(psContaCorrenteId: string) {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/listar-movimento-conta-corrente-manual/?psContaCorrenteId=${psContaCorrenteId}`
    );
  }

  /**
   * Requisição HTTP para adicionar movimento  conta corrente
   */
  adicionarMovimentoContaCorrente(params: any) {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/adicionar-movimento-conta-corrente`,
      params
    );
  }

  /**
   * Requisição HTTP para excluir movimento conta corrente
   */
  excluirMovimentoContaCorrenteManual(params: any) {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/excluir-movimento-conta-corrente-manual`,
      params
    );
  }

  /**
   * Requisição HTTP para obter saldos conta corrrente
   */
  obterSaldosContaCorrente(psContaCorrenteId: string): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/obter-saldos-conta-corrente/?psContaCorrenteId=${psContaCorrenteId}`
    );
  }

  /**
   * Requisição HTTP para obter bandeiras de cartão de crédito
   */
  listarBandeiras(): Observable<any> {
    return this._httpRequest.get<any>(`/v1/bandeira/listar`);
  }

  /**
   * Requisição HTTP para obter tarifas padrões vigentes das instituições financeiras
   */
  listarPreficicacaoInstituicaoFinanceiraVigente(): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/listar-precificacoes-instituicao-financeira-vigente`
    );
  }

  /**
   * Requisição HTTP para salvar tarifas padrões vigentes das instituições financeiras
   */
  criarPrecificacoesInstituicaoFinanceiraVigente(params: any): Observable<any> {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/criar-precificacoes-instituicao-financeira-vigente`,
      params
    );
  }

  /**
   * Requisição HTTP para criar conta corrente meio de pagamento
   */
  criarContaCorrenteMeioPagamento(params: any) {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/criar-conta-corrente-meio-pagamento`,
      params
    );
  }

  /**
   *  Requisição HTTP para lsitar precificações conta corrente meio de pagamento
   */
  listarPrecificacoesContaCorrenteMeioPagamento(
    psContaCorrenteMeioPagamentoId: string
  ): Observable<any> {
    return this._httpRequest.get<any>(
      `/v1/contacorrente/listar-precificacoes-conta-corrente-meio-pagamento/?psContaCorrenteMeioPagamentoId=${psContaCorrenteMeioPagamentoId}`
    );
  }

  /**
   * Requisição HTTP para ativar conta corrente meio de pagamento administrador
   */
  AtivarContaCorrenteMeioPagamentoAdministrador(params: any) {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/ativar-conta-corrente-meio-pagamento-administrador`,
      params
    );
  }

  /**
   * Requisição HTTP para ativar conta corrente meio de pagamento cliente
   */
  AtivarContaCorrenteMeioPagamentoCliente(params: any) {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/ativar-conta-corrente-meio-pagamento-cliente`,
      params
    );
  }

  /**
   * Requisição HTTP para inativar conta corrente meio de pagamento
   */
  InativarContaCorrenteMeioPagamentoCliente(params: any) {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/inativar-conta-corrente-meio-pagamento`,
      params
    );
  }

  /**
   * Requisição HTTP para encerrar conta corrente meio de pagamento
   */
  encerrarContaCorrenteMeioPagamentoCliente(params: any) {
    return this._httpRequest.post<any>(
      `/v1/contacorrente/encerrar-conta-corrente-meio-pagamento`,
      params
    );
  }
}
