interface apps {
    id: number;
    img: string;
    title: string;
    subtitle: string;
    link: string;
}

export const APP_LIST: apps[] = [
    {
        id: 1,
        img: '/assets/images/svgs/icon-dd-chat.svg',
        title: 'ERP',
        subtitle: 'Backoffice de gestão educacional',
        link: '/apps/chat',
    },
    {
        id: 2,
        img: '/assets/images/svgs/icon-dd-chat.svg',
        title: 'DOCS',
        subtitle: 'Gestão eletrônica de documentos e assinaturas',
        link: '/apps/chat',
    },
    {
        id: 2,
        img: '/assets/images/svgs/icon-dd-chat.svg',
        title: 'DIPLOMAS',
        subtitle: 'Emissão e registro de diplomas',
        link: '/apps/chat',
    }
];
