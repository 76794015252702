import { Component, Input, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoadingService } from './core/loading/loading.service';
import { delay } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = environment.name;

   public _loadingService = inject(LoadingService);

  constructor(
  ){
     // this._loadingService.show();
   }

  ngOnInit() {
     // this._loadingService.hide();
  }

}
