import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { APP_LIST } from '../apps';
import { AppSettings } from 'src/app/app.config';
import { CoreService } from 'src/app/shared/services/core.service';
import { ContaCorrenteService } from 'src/app/features/conta-corrente/contacorrente.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormBuilder } from '@angular/forms';
import { AutenticacaoService } from 'src/app/features/autenticacao/autenticacao.service';
import { LoginResult } from 'src/app/features/autenticacao/autenticacao.model';
import { ModalSelecionarContacorrenteComponent } from '../modal-selecionar-contacorrente/modal-selecionar-contacorrente.component';
import { NotificacaoService } from 'src/app/core/notificacao/notificacao.service';
import { TipoNotificacao } from 'src/app/core/notificacao/notificacao.model';

interface notifications {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}
interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}
@Component({
  selector: 'app-header',
  imports: [RouterModule, CommonModule, NgScrollbarModule, TablerIconsModule, MaterialModule, SharedModule],
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true
})
export class HeaderComponent {

  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();
  @Output() optionsChange = new EventEmitter<AppSettings>();

  showFiller = false;
  private _fb = inject(FormBuilder);
  private _contaCorrenteService = inject(ContaCorrenteService);
  private _autenticacaoService = inject(AutenticacaoService);
  private _notificacaoService = inject(NotificacaoService);
  public usuarioLogado: LoginResult | null;
  contasCorrente: any;
  currentContaCorrente:any;

  public form = this._fb.group({
    contaCorrenteId: this._fb.control<string | null>(null)
  });

  exibeContasCorrente: boolean = false;
  private _dialog = inject(MatDialog);

  constructor(
    public dialog: MatDialog,
    private settings: CoreService
  ) {
  }

  ngOnInit() {
    this.usuarioLogado = this._autenticacaoService.obterDadosUsuarioLogado();
    if (this.usuarioLogado?.usuarioInfo?.perfil == "Cliente") {
      this.listarContasCorrente();
      this.exibeContasCorrente = true;
      if (localStorage.getItem('currentContaCorrente')) {
        this.currentContaCorrente = JSON.parse(localStorage.getItem('currentContaCorrente') ?? "");
      }
    }
  }

  listarContasCorrente() {
    this._contaCorrenteService.listarContasCorrente().subscribe
      ({
        next: (resContaCorrente: any) => {
          this.contasCorrente = resContaCorrente.data?.contasCorrente;
          var contacorrentepadrao = this.contasCorrente.filter((x: { contaPadrao: boolean; }) => x.contaPadrao == true)[0];
          this.form.patchValue({
            contaCorrenteId: contacorrentepadrao.contaCorrenteId
          });
        },
        error: (error) => {
          this._notificacaoService.show(TipoNotificacao.erro, error.error.message, "Atenção");
        }
      });
  }

  selecionarContaCorrente(event: any) {
    var currentContaCorrente = this.contasCorrente.filter((x: { contaCorrenteId: string; }) => x.contaCorrenteId == event.value)[0];
    let lobjRequestDefinirContaCorrente = {
      contaCorrenteId: currentContaCorrente.contaCorrenteId
    }
    this._contaCorrenteService.definirContaPadrao(lobjRequestDefinirContaCorrente).subscribe
      ({
        next: (resDefinirContaCorrentePadrao: any) => {
          localStorage.setItem('currentContaCorrente', JSON.stringify(currentContaCorrente));
          window.location.reload();
        },
        error: (error) => {
          this._notificacaoService.show(TipoNotificacao.erro, error.error.message, "Atenção");
        }
      })
  }

  options = this.settings.getOptions();
  notifications: notifications[] = [
  ];

  profiledd: profiledd[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'Meu perfil',
      subtitle: 'Configurações da sua conta Perseus',
      link: '/',
    }
  ];

  apps = APP_LIST;

  toogleTheme() {
    this.options.theme = this.options.theme == 'dark' ? 'light' : 'dark';
    this.optionsChange.emit(this.options);
  }

  selecionarContaBancariaModal() {
    const dialogRef = this._dialog.open(ModalSelecionarContacorrenteComponent, {
      height: '100%',
      width: '400px',
      position: { right: '0', top: '0' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result){
        var currentContaCorrente = this.contasCorrente.filter((x: { contaCorrenteId: string; }) => x.contaCorrenteId == result.contaCorrenteId)[0];
        let lobjRequestDefinirContaCorrente = {
        contaCorrenteId: currentContaCorrente.contaCorrenteId
      }
      this._contaCorrenteService.definirContaPadrao(lobjRequestDefinirContaCorrente).subscribe
        ({
          next: (resDefinirContaCorrentePadrao: any) => {
            localStorage.setItem('currentContaCorrente', JSON.stringify(currentContaCorrente));
            window.location.reload();
          },
          error: (error) => {
            this._notificacaoService.show(TipoNotificacao.erro, error.error.message, "Atenção");
          }
        });
      }
    });
  }

  logout() {
    localStorage.removeItem('currentContaCorrente');
    this._autenticacaoService.logout(false);
  }
}