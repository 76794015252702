import { NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { TipoNotificacao } from 'src/app/core/notificacao/notificacao.model';
import { NotificacaoService } from 'src/app/core/notificacao/notificacao.service';
import { LoginResult } from 'src/app/features/autenticacao/autenticacao.model';
import { AutenticacaoService } from 'src/app/features/autenticacao/autenticacao.service';
import { ContaCorrenteService } from 'src/app/features/conta-corrente/contacorrente.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-modal-selecionar-contacorrente',
  standalone: true,
  imports: [SharedModule ],
  templateUrl: './modal-selecionar-contacorrente.component.html',
  styleUrl: './modal-selecionar-contacorrente.component.scss'
})
export class ModalSelecionarContacorrenteComponent {

  private _contaCorrenteService = inject(ContaCorrenteService);
  private _autenticacaoService = inject(AutenticacaoService);
  private _notificacaoService = inject(NotificacaoService);
  public usuarioLogado : LoginResult | null;
  contasCorrente: any;
  public _loadDataSpinner: boolean;

  constructor(public dialogRef: MatDialogRef<ModalSelecionarContacorrenteComponent>) {
  }
  ngOnInit(){
    
    this.usuarioLogado = this._autenticacaoService.obterDadosUsuarioLogado();
    if(this.usuarioLogado?.usuarioInfo?.perfil == "Cliente"){
      this.listarContasCorrente();
    }
  }
  listarContasCorrente() 
  {
    this._loadDataSpinner = true;
    this._contaCorrenteService.listarContasCorrente().subscribe
      ({
        next: (resContaCorrente: any) => {
          this.contasCorrente = resContaCorrente.data?.contasCorrente;
          var contacorrentepadrao = this.contasCorrente.filter((x: { contaPadrao: boolean; })=>x.contaPadrao==true)[0];
        },
        error: (error) => {
          this._notificacaoService.show(TipoNotificacao.erro, error.error.message, "Atenção");
        }
      }).add(() => {
        this._loadDataSpinner = false;
      });
  }
  selecionar(contacorrente:any){
    this.dialogRef.close(contacorrente);
  }
}