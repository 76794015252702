import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { LayoutComponent } from './shared/components/layout/layout.component';

import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { HttpAuthInterceptor } from './shared/interceptors/auth.interceptor';
import { TransacaoCancelarComponent } from './features/cobranca/transacao-cancelar/transacao-cancelar.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { provideNgxMask } from 'ngx-mask';
import { LoginComponent } from './features/autenticacao/login/login.component';
import { LoaderInterceptor } from './core/loading/LoaderInterceptor';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

registerLocaleData(ptBr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutComponent
  ],
  exports: [],
  providers: [
    [provideCharts(withDefaultRegisterables())],
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true,
   },
   provideNgxMask(),
   {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
   {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true
  }
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
