import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AutenticacaoService } from '../autenticacao.service';
import { LoginParams, LoginParamsInterno, LoginResultInterno } from '../autenticacao.model';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from '../../../shared/shared.module';
import { TipoNotificacao } from 'src/app/core/notificacao/notificacao.model';
import { NotificacaoService } from 'src/app/core/notificacao/notificacao.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  private _router = inject(Router);
  private _autenticacaoService = inject(AutenticacaoService);
  private _notificacaoService = inject(NotificacaoService);

  public lsToken: string;
  public lsUsuarioId: string;
  public lsIdConvite: string;
  loginParams: LoginParams;
  loginParamsInterno: LoginParamsInterno;
  contaCorrentes: any[];
  usuario: any;
  checkContaCorrenteExistente: boolean = true;
  _loadingSplashScreen: boolean = false;

  ngOnInit() {

    this._loadingSplashScreen = true;
 

    if (localStorage.getItem('currentUser')) {
      let returnUrl = '/conta-corrente/extrato';
      this._router.navigate([returnUrl]);
    } else {

      this.lsToken = this._router.routerState.snapshot.root.queryParamMap.get("token") ?? "";
      this.lsUsuarioId = this._router.routerState.snapshot.root.queryParamMap.get("usuarioId") ?? "";
      this.lsIdConvite = this._router.routerState.snapshot.root.queryParamMap.get("conviteId") ?? "";

      if (this.lsToken != "") {
        this.login();
      } else {
        window.location.href = environment.auth.uri + `/autenticacao/login?scope_action=external&return_url=${window.location.href}`;
      }
    }
  }

  login() {
    this.loginParams = {
      usuarioId: this._router.routerState.snapshot.root.queryParamMap.get("usuarioId") ?? "",
      token: this._router.routerState.snapshot.root.queryParamMap.get("token") ?? "",
      aplicacaoId: "Perseus.Pay"
    };

    this._autenticacaoService.loginAuth(this.loginParams)
    .subscribe
      ({
        next: (resLoginExterno) => {
          // PROCESSO DE LOGIN INTERNO
          // Checa o Perfil do Usuário
          let perfilUsuario = resLoginExterno.data.usuarioInfo.perfil ?? "Cliente";

          this.loginParamsInterno = {
            nome: resLoginExterno.data.usuarioInfo.nomeCompleto,
            email: resLoginExterno.data.usuarioInfo.email,
            perseusAuthId: resLoginExterno.data.usuarioInfo.id
          };

          this._autenticacaoService.login(this.loginParamsInterno)
          .pipe(finalize(() => this._loadingSplashScreen = false ))    
          .subscribe
            ({
              next: (resLoginInterno) => {
                this.usuario = resLoginInterno.data;
                if (perfilUsuario == "Cliente") {
                  this.contaCorrentes = resLoginInterno.data?.contasCorrente;
                  if (this.contaCorrentes.length == 0 && this.lsIdConvite == "") {
                    this.checkContaCorrenteExistente = false;
                    this._autenticacaoService.logout(); 
                  }
                  else if (this.lsIdConvite != "") {
                    this.redirecionarAceite();
                  }
                  else {
                    let contaCorrentePadrao = this.contaCorrentes.filter(x => x.contaPadrao == true)[0];
                    if (contaCorrentePadrao != null) {
                      this.selecionarContaCorrente(contaCorrentePadrao);
                    }
                    else // DEFINI A PRIMEIRA CONTA COMO PADRÃO
                    {
                      this.definirContaPadrao(this.contaCorrentes[0]);
                    }
                  }
                  
                }
                else if (perfilUsuario == "Administrador") {
                  this.checkContaCorrenteExistente = true;
                  this._router.navigate(['/clientes']);
                }
                else if (perfilUsuario == "Implantador") {
                  this.checkContaCorrenteExistente = true;
                  this._router.navigate(['/clientes']);
                }
                else if (perfilUsuario == "Atendente") {
                  this.checkContaCorrenteExistente = true;
                  this._router.navigate(['/clientes']);
                }
                else {
                  this._notificacaoService.show(TipoNotificacao.erro, "Perfil não implementado", "Atenção");
                  window.location.href = environment.auth.uri
                }
                this._loadingSplashScreen = false
              },
              error: (error) => {
                this._notificacaoService.show(TipoNotificacao.erro, error.error.message, "Atenção");
                this._loadingSplashScreen = false
              }
            });
          // FIM PROCESSO LOGIN INTERNO
        },
        error: (error) => {
          this._notificacaoService.show(TipoNotificacao.erro, error.message, "Atenção");
        }
      });
  }

  async definirContaPadrao(contaCorrente: any) {
    this._autenticacaoService.definirContaPadrao(contaCorrente)
      .subscribe
      ({
        next: (resContaPadrao) => {

          if (this.lsIdConvite != "") {
            this.redirecionarAceite();
          } else {
            localStorage.setItem('currentContaCorrente', JSON.stringify(contaCorrente)); // Trocar
            this._router.navigate(['/conta-corrente/extrato']);
          }
        }, error: (error) => {
          this._notificacaoService.show(TipoNotificacao.erro, error.error.message, "Atenção");
        }
      });
  }

  async selecionarContaCorrente(contaCorrente: any) {
    if (this.lsIdConvite != "") {
      this.redirecionarAceite();
    } else {
      localStorage.setItem('currentContaCorrente', JSON.stringify(contaCorrente)); // Trocar
      this._router.navigate(['/conta-corrente/extrato']);
    }
  }


  redirecionarAceite() {
    this._router.navigate(['/convite-usuario'], {
      queryParams: {
        conviteId: this.lsIdConvite
      }
    });
  }

  retornarLogin() {
    window.location.href = environment.aplicacao.url
  }

}
