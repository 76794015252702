@if(pageInfo?.['title'] != 'Extrato de movimentações' && pageInfo?.['title'] != 'Transações' && pageInfo?.['title'] != 'Instituição Financeira'  &&  pageInfo?.['title'] != 'Contas Corrente' && pageInfo?.['title'] != 'Clientes' && pageInfo?.['title'] != 'Conciliações' && pageInfo?.['title'] != 'Painel' && pageInfo?.['title'] != 'Conta Bancária' && pageInfo?.['title'] != 'Saques'&& pageInfo?.['title'] != 'Usuários' && pageInfo?.['title'] != ''){
<div class="bg-light-primary rounded p-y-20 p-x-24 m-b-30 overflow-hidden">
  <div class="row">
    <div class="col-sm-8">
      <h4 class="page-title m-0 f-s-20 f-w-600 m-b-6 d-flex align-items-center">
        @if(pageInfo?.['returnUrl']) {
        <div class="d-flex pointer" matTooltip="Voltar" matTooltipPosition="above"
          [routerLink]=" pageInfo?.['returnUrl']">
          <i-tabler name="arrow-left" class="icon-30 m-r-4"></i-tabler>
        </div>
        }
        {{ pageInfo?.['title'] }}
      </h4>
      <div class="d-flex align-items-center overflow-hidden">
        <ul class="breadcrumb">
          @for (url of pageInfo?.['urls']; track url.url;let index = $index,last
          = $last, first = $first ) {

          @if(!first){
          <li class="breadcrumb-item">
            <i-tabler name="circle-filled" class="icon-8"></i-tabler>
          </li>
          }

          @if (url.url) {
          <li class="breadcrumb-item" [routerLink]="url.url">
            <a [routerLink]="url.url">{{ url.title }}</a>
          </li>
          }
          @else {
          <li class="breadcrumb-item active">{{ url.title }}</li>
          }
          }
        </ul>
      </div>
    </div>
  </div>
</div>
}
