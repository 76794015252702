import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {
  private _uri = environment.api.uri;
  private _uriAuth = environment.auth_api.uri;
  private _http = inject(HttpClient);

  loginAuth<T>(url: string, body: any): Observable<T> {
    return this.httpRequest('post', url, { body: body }, true);
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.httpRequest('post', url, { body: body });
  }

  postBlob<T>(url: string, body: any,): Observable<T> {
    return this.httpRequest('post', url, { body: body, observe: 'response', responseType: 'blob', });
  }

  postFormData<T>(url: string, formData: FormData){
    return this.httpRequest('post',url, { body: formData, headers:{
      'Content-Type': 'multipart/form-data' // <- HERE
    }} );
  }

  get<T>(url: string, params?: any): Observable<T> {
    return this.httpRequest('get', url, { params: params });
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.httpRequest('put', url, { body: body });
  }

  delete(url: string): Observable<any> {
    return this.httpRequest('delete', url, {});
  }

  private httpRequest(method: string, url: string, options: any, isAuth: boolean = false): Observable<any> {
    return this._http.request(method, `${isAuth ? this._uriAuth : this._uri}${url}`, { ...options }).pipe(take(1));
  }
}
