import { NavItem } from './nav-item';

export const navItemsUsuarioPerseus: NavItem[] = [
  // {
  //   navCap: 'Início',
  // },
  // {
  //   displayName: 'Dashboard',
  //   iconName: 'layout-dashboard',
  //   route: '/dashboard',
  // },
  {
    navCap: 'Usuário',
    navCapMobile: 'Usua.',
  },
];
