import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { HttpRequestService } from 'src/app/core/http-request/http-request.service';
import { Response, ResponseBase } from 'src/app/core/models/response.model';
import { LoginParams, LoginParamsInterno, LoginResult, LoginResultInterno, RenovarTokenModel } from './autenticacao.model';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {
  private _router = inject(Router);
  private _storage = inject(LocalStorageService);
  private _httpRequest = inject(HttpRequestService);

  /**
   * Requisição HTTP para realizar o login do usuário
   */
  loginAuth(params: LoginParams): Observable<Response<LoginResult>> {
    this.logout();
    return this._httpRequest.loginAuth<Response<LoginResult>>('/v1/login-externo', params).pipe(
      tap(p => {
        this.salvarInfoUsuarioLogado(p.data);
      })
    );
  }

  /**
   * Requisição HTTP para realizar o login do usuário
   */
  login(params: LoginParamsInterno): Observable<Response<LoginResultInterno>> {
    return this._httpRequest.post<Response<LoginResultInterno>>('/v1/usuario/login-usuario', params);
  }

  renovarToken() {
    const lDadosUsuarioLogado = this.obterDadosUsuarioLogado();
    let params: RenovarTokenModel = {
      usuarioId: lDadosUsuarioLogado!.usuarioInfo.id,
      refreshToken: lDadosUsuarioLogado!.refreshToken,
    };

    return this._httpRequest.put<Response<LoginResult>>('/v1/renovar-token', params).pipe(
      tap(p => {
        this.salvarInfoUsuarioLogado(p.data);
      })
    );
  }

  logout(desativarRedirect?: boolean) {
    this.limparInfoUsuarioLogado(desativarRedirect);
  }

  /**
   * Recupera os dados do usuário logado.
   */
  obterDadosUsuarioLogado(): LoginResult | null {
    return this._storage.get<LoginResult>(environment.keys.userInfo);
  }

  /**
   * Faz o logout do usuário
   */
  private limparInfoUsuarioLogado(desativarRedirect?: boolean) {
    this._storage.remove(environment.keys.userInfo);
    if (!desativarRedirect) {

      //TODO: Revisar esse ponto, pois neste caso o redirect deve ser para o login do Auth
      this._router.navigate(['login'], { queryParamsHandling: 'preserve' });
    }
  }

  /**
   * Registra as informações do usuário autenticado.
   */
  private salvarInfoUsuarioLogado(loginResult: LoginResult) {
    const infoUsuario: LoginResult = {
      refreshToken: loginResult.refreshToken,
      token: loginResult.token,
      usuarioInfo: {
        apelido: loginResult.usuarioInfo.apelido,
        email: loginResult.usuarioInfo.email,
        id: loginResult.usuarioInfo.id,
        nomeCompleto: loginResult.usuarioInfo.nomeCompleto,
        avatar: loginResult.usuarioInfo.avatar ?? "assets/images/profile/user-7.jpg",
        perfil:loginResult.usuarioInfo.perfil ?? "Cliente"
      }
    };

    this._storage.set(environment.keys.userInfo, infoUsuario);
  }

  /**
   * Registra as informações conta corrente usuário logado
   */
  private salvarInfoContaCorrenteUsuarioLogado(contaCorrente: LoginParamsInterno) {
    this._storage.set(environment.keys.contaInfo, contaCorrente);
  }

  /**
   * Recupera os dados do usuário logado.
   */
  obterDadosContaCorrenteUsuarioLogado(): LoginParamsInterno | null {
    return this._storage.get<LoginParamsInterno>(environment.keys.contaInfo);
  }

  /**
   * Definição de Conta Padrão Usuário
   */
  definirContaPadrao(params: any): Observable<any> {
    return this._httpRequest.post<any>('/v1/usuario/definir-conta-padrao', params);
  }
}
