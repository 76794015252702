import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { catchError, switchMap, throwError } from "rxjs";
import { LoadingService } from "src/app/core/loading/loading.service";
import { TipoNotificacao } from "src/app/core/notificacao/notificacao.model";
import { NotificacaoService } from "src/app/core/notificacao/notificacao.service";
import { AutenticacaoService } from "src/app/features/autenticacao/autenticacao.service";
import { environment } from "src/environments/environment";

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
    private jwtHelper: JwtHelperService = new JwtHelperService();
    private isRefreshingToken = false;
    private _autenticacaoService = inject(AutenticacaoService);
    private _notificacaoService = inject(NotificacaoService);

    intercept(pRequest: HttpRequest<any>, pNext: HttpHandler) {

        // let lcontentType = pRequest.headers.get("Content-Type"); 
        /* let lRequest = pRequest.clone({
            headers: pRequest.headers.set('Content-Type', lcontentType=='multipart/form-data' ? lcontentType : 'application/json'),
        });  */

        let lRequest = pRequest.clone({
            headers: pRequest.headers.set('Content-Type','application/json')           
        });

        let lInfoUsuario = this._autenticacaoService.obterDadosUsuarioLogado();
        if (lInfoUsuario) {
            if (!this.jwtHelper.isTokenExpired(lInfoUsuario.token)) {
                lRequest = this.atribuirJwtToken(lRequest, lInfoUsuario.token);
            } else {
                return this.handleRefreshToken(lRequest, pNext);
            }
        }

        return pNext.handle(lRequest).pipe(catchError(err => {
            // this._notificacaoService.show(TipoNotificacao.erro, "Suas credenciais de acesso expiraram, é necessário fazer fazer login novamente.");
            if (err.status === 401 || err.status==0) {
                localStorage.clear();
                window.location.href = environment.aplicacao.url;
            }
            return throwError(()=> err);
        })); 
    }

    private atribuirJwtToken(pRequest: HttpRequest<any>, psToken: string): HttpRequest<any> {
        return pRequest.clone({
            headers: pRequest.headers.set(
                'Authorization',
                'Bearer ' + psToken
            ),
        });
    }

    private handleRefreshToken(pRequest: HttpRequest<any>, pNext: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            return this._autenticacaoService.renovarToken().pipe(
                switchMap((pResponse) => {
                    this.isRefreshingToken = false;
                    const lRequest = this.atribuirJwtToken(pRequest, pResponse.data.token);
                    return pNext.handle(lRequest);
                }),
                catchError(() => {
                    this.isRefreshingToken = false;
                    return this.handleLogout();
                })
            );
        }

        return pNext.handle(pRequest);
    }

    private handleLogout() {
        this._autenticacaoService.logout();
        return throwError(
            () =>
                new Error(
                    'Suas credenciais de acesso expiraram, é necessário fazer fazer login novamente.'
                )
        );
    }
}
