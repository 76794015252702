<mat-toolbar class="topbar">
  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  @if(showToggle) {
  <button
    mat-icon-button
    (click)="toggleCollapsed.emit()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  }

  <!-- Mobile Menu -->
  @if(!showToggle) {
  <button
    mat-icon-button
    (click)="toggleMobileNav.emit()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  }

  <button
    *ngIf="exibeContasCorrente"
    mat-icon-button
    class="d-lg-flex justify-content-center"
    (click)="selecionarContaBancariaModal()"
    matTooltip="Alterar conta corrente"
  >
    <i-tabler name="transform" class="icon-20 d-flex"></i-tabler>
  </button>

  <div
    *ngIf="exibeContasCorrente"
    class="f-s-14 m-0 mat-subtitle-1"
    style="line-height: 16px"
  >
    <small
      ><strong>{{ currentContaCorrente?.razaoSocial }}</strong></small
    >
    <br />
    <small
      >AG/CC: {{ currentContaCorrente?.agencia }} /
      {{ currentContaCorrente?.conta }}</small
    >
  </div>

  <!-- <div class="row" *ngIf="exibeContasCorrente">
    <form  [formGroup]="form!">
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-200 hide-hint">
        <mat-select required  formControlName="contaCorrenteId"  (selectionChange)="selecionarContaCorrente($event)">
          <mat-option *ngFor="let item of contasCorrente"
                  [value]="item.contaCorrenteId">Conta: {{item.conta}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    </form>
  </div> -->

  <!--  <div class="d-none d-lg-flex">
    <mat-menu #appsmenu="matMenu" class="apps-dd cardWithShadow">
      <div class="row">
        <div class="col-sm-12">
          <div class="p-32 p-b-0">
            <div class="row">
              @for(appdd of apps; track appdd.title) {
              <div class="col-sm-6 text-hover-primary">
                <a
                  [routerLink]="[appdd.link]"
                  class="d-flex align-items-center text-decoration-none m-b-24"
                >
                  <span
                    class="text-primary bg-light-primary rounded icon-40 d-flex align-items-center justify-content-center"
                  >
                    <img [src]="appdd.img" width="20" />
                  </span>

                  <div class="m-l-16">
                    <h5
                      class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text"
                      style="line-height: 15px"
                    >
                      {{ appdd.title }}
                    </h5>
                    <span class="mat-body-1 f-s-12">{{ appdd.subtitle }}</span>
                  </div>
                </a>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </mat-menu>
  </div> -->

  <span class="flex-1-auto"></span>

  <!-- Mobile Menu
  <button
    mat-icon-button
    (click)="toggleMobileFilterNav.emit()"
    class="d-flex d-lg-none justify-content-center"
  >
    <i-tabler name="grid-dots" class="icon-20 d-flex"></i-tabler>
  </button> -->

  <!-- <button
    mat-icon-button
    [matMenuTriggerFor]="appsmenu"
    class="d-none d-lg-flex justify-content-center"
  >
    <i-tabler name="grid-dots" class="icon-20 d-flex"></i-tabler>
  </button>

  <button *ngIf="exibeContasCorrente"
    mat-icon-button
    class="d-none d-lg-flex justify-content-center"
    (click)="selecionarContaBancariaModal()"
    matTooltip="Selecionar conta corrente"

  >
    <i-tabler name="building-bank" class="icon-20 d-flex"></i-tabler>
  </button>-->

  <button
    mat-icon-button
    (click)="toogleTheme()"
    class="d-flex justify-content-center"
  >
    <i-tabler name="sun-high" class="icon-20 d-flex"></i-tabler>
  </button>

  <button
    mat-icon-button
    [matMenuTriggerFor]="notificationmenu"
    aria-label="Notificações"
    matTooltip="Notificações"
  >
    <i-tabler class="d-flex" name="bell"></i-tabler>
    <!-- <i-tabler class="d-flex" name="bell" matBadge="1" matBadgeColor="primary"></i-tabler> -->
  </button>
  <mat-menu #notificationmenu="matMenu" class="topbar-dd cardWithShadow">
    <div
      class="d-flex align-items-center p-x-32 p-y-16"
      style="padding-bottom: 0px !important"
    >
      <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Notificações</h6>
      <!-- <span class="m-l-auto">
        <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12">5 new</span>
      </span> -->
    </div>

    <!-- @for(notification of notifications; track notification.title) {
    <button mat-menu-item class="p-x-32 p-y-16">
      <div class="d-flex align-items-center">
        <img [src]="notification.img" class="rounded-circle" width="48" />
        <div class="m-l-16">
          <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
            {{ notification.title }}
          </h5>
          <span>{{ notification.subtitle }}</span>
        </div>
      </div>
    </button>
    } -->

    <div class="p-y-10 p-x-32" style="padding-top: 0px">
      <span>Nenhuma notificação nova</span>
      <!-- <button mat-stroked-button color="primary" class="w-100">
        See all notifications
      </button> -->
    </div>
  </mat-menu>

  <button
    mat-icon-button
    [matMenuTriggerFor]="profilemenu"
    aria-label="Perfil do usuário"
    class="m-l-4"
  >
    <img
      [src]="usuarioLogado?.usuarioInfo?.avatar"
      class="rounded-circle object-cover icon-35 profile-dd"
      width="35"
    />
  </button>

  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <ng-scrollbar class="position-relative" style="height: 280px">
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Perfil do usuário</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <img
            [src]="usuarioLogado?.usuarioInfo?.avatar"
            class="rounded-circle"
            width="95"
          />
          <div class="m-l-16">
            <h6 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
              {{ usuarioLogado?.usuarioInfo?.apelido }}
            </h6>
            <span class="f-s-14 d-block m-b-4">{{
              usuarioLogado?.usuarioInfo?.perfil
            }}</span>
            <span class="d-flex align-items-center">
              <i-tabler name="mail" class="icon-15 m-r-4"></i-tabler>
              {{ usuarioLogado?.usuarioInfo?.email }}
            </span>
          </div>
        </div>
      </div>

      <div class="p-y-12 p-x-32">
        <a (click)="logout()" mat-stroked-button color="primary" class="w-100"
          >Sair</a
        >
      </div>
    </ng-scrollbar>
  </mat-menu>
</mat-toolbar>
