
import { Component, Input } from '@angular/core';
import { MaterialModule } from '../../../material.module';
import { TablerIconsModule } from 'angular-tabler-icons';
import { CardMoneyInfo } from './card-money.model';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-card-money',
    standalone: true,
    imports: [MaterialModule, TablerIconsModule, CommonModule],
    templateUrl: './card-money.component.html',
})
export class CardMoneyComponent {
    @Input() cardInfo?: CardMoneyInfo;
}