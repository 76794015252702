<div class="container">
  <mat-card class="cardWithShadow" style="padding: 30px">
    <div class="row">
      <div class="col-md-12 text-center">
        <img src="./assets/images/logos/dark-logo.png" alt="error-bg" style="width: 200px; height: 100" />
      </div>
      <mat-progress-bar mode="query" *ngIf="_loadDataSpinner"></mat-progress-bar>
      <div class="col-md-12 text-center" style="margin-bottom: 20px" *ngIf="!_loadDataSpinner && !_showError">
        <h2>Parabéns!</h2>
      </div>
      <div class="col-md-12 bg-light-info" style="margin-bottom: 20px" *ngIf="!_loadDataSpinner && !_showError">
        <p>
          Seu acesso foi liberado com sucesso ! <strong> AG: {{ dadosConviteContaCorrente?.agencia }} / CC: {{
            dadosConviteContaCorrente?.conta }}</strong>
        </p>
      </div>
      <hr />
      <div class="col-md-12 bg-light-error" style="margin-bottom: 20px; border-radius: 10px;"
        *ngIf="!_loadDataSpinner && _showError">
        <div style="font-size:12px;">
          <div class="bg-light-error" style="border-radius: 10px; padding:8px;">
            {{ descricaoErro }}
          </div>
        </div>
      </div>
      <div class="col-md-12 text-center" *ngIf="!_loadDataSpinner && !_showError">
        <a mat-flat-button color="primary" (click)="retornarHome()">Retornar para o home</a>
      </div>
      <div class="col-md-12 text-center" *ngIf="_showError">
        <a mat-flat-button color="primary" (click)="retornarLogin()">Retornar para o Login</a>
      </div>
    </div>
  </mat-card>
</div>