<h2 mat-dialog-title class="m-b-10">Selecione uma conta corrente</h2>
<mat-divider></mat-divider>
<mat-dialog-content *ngIf="_loadDataSpinner" style="margin-top: 40px;">
  <div class="d-flex justify-content-center mt-4">
    <mat-spinner [diameter]="50"> </mat-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-content>
  <div *ngFor="let item of contasCorrente">
    <div class="card" style="cursor: pointer" (click)="selecionar(item)">
      <div class="card-body">
        <div
          class="row"
          style="border-left: 4px solid"
          [ngStyle]="
            item.contaPadrao
              ? { 'border-color': '#80bf1e' }
              : { 'border-color': '#f5f5f5' }
          "
        >
          <div class="col-md-12" style="line-height: 22px; font-size: 0.8rem">
            <strong> {{ item.razaoSocial }} </strong>
            <br />AG/CC: {{ item.agencia }} / {{ item.conta }}
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
