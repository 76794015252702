import { NavItem } from './nav-item';

export const navItensImplantadorPerseus: NavItem[] = [
  // {
  //   navCap: 'Início',
  // },
  // {
  //   displayName: 'Dashboard',
  //   iconName: 'layout-dashboard',
  //   route: '/dashboard',
  // },
  {
    navCap: 'Implantador',
    navCapMobile: 'Imp',
  },
  {
    displayName: 'Clientes',
    iconName: 'users',
    route: '/clientes',
  },
  {
    displayName: 'Contas correntes',
    iconName: 'building-bank',
    route: '/conta-corrente/contas-correntes',
  },

  {
    displayName: 'Transações',
    iconName: 'credit-card-pay',
    route: '/cobranca/transacoes',
  },
  {
    displayName: 'Saques',
    iconName: 'receipt-2',
    route: '/conta-corrente/saque',
  },
  {
    displayName: 'Contas bancárias',
    iconName: 'premium-rights',
    route: 'configuracoes/contas-bancarias',
  },
  {
    displayName: 'Configurações',
    iconName: 'settings',
    route: 'configuracoes/administrador',
  }
];
