import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AutenticacaoService } from '../../features/autenticacao/autenticacao.service';

@Injectable({
  providedIn: 'root',
})
class AuthGuardService {
  constructor(
    private _router: Router,
    private _autenticacaoService: AutenticacaoService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if  (this._autenticacaoService.obterDadosUsuarioLogado() != null) {
      return true;
    } else {
      localStorage.clear();
      this._router.navigate(['login'], { queryParams:next.queryParams,queryParamsHandling:"merge"});
      return false;
    }
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(AuthGuardService).canActivate(next, state);
};
